/**
 * Basic typography style for text
 * Warning: These styles overrides without the default .dw-mod class, as they all target the most basic required elements
 */
h1 {
  hyphens: auto;
  -webkit-hyphens: auto;
}
h2 {
  hyphens: auto;
  -webkit-hyphens: auto;
}
h4 {
  font-family: "GlossAndBloom";
  font-weight: normal;
  font-size: 43px;
}
@media (max-width: 768px) {
  h4 {
    font-size: 28px;
  }
}
a#CybotCookiebotDialogPoweredbyCybot,
div#CybotCookiebotDialogPoweredByText {
  display: none;
}
html {
  scroll-padding-top: 300px;
}
.corner-position-br .inline-frame:after {
  border: 3px solid #ffffff;
  margin: 12px;
  border-bottom-right-radius: 140px 80px;
}
.corner-position-br .color-frame img {
  border-bottom-right-radius: 140px 80px;
}
.corner-position-bl .inline-frame:after {
  border: 3px solid #ffffff;
  margin: 12px;
  border-bottom-left-radius: 140px 80px;
}
.corner-position-bl .color-frame img {
  border-bottom-left-radius: 140px 80px;
}
.corner-position-tr .inline-frame:after {
  border: 3px solid #ffffff;
  margin: 12px;
  border-top-right-radius: 140px 80px;
}
.corner-position-tr .color-frame img {
  border-top-right-radius: 140px 80px;
}
.corner-position-tl .inline-frame:after {
  border: 3px solid #ffffff;
  margin: 12px;
  border-top-left-radius: 140px 80px;
}
.corner-position-tl .color-frame img {
  border-top-left-radius: 140px 80px;
}
.dealers-map-lineheight {
  line-height: 38px;
}
.content-row__item.dw-mod {
  min-width: 0;
}
.image-paragraph {
  min-height: 550px;
}
.bellissa-warranty-form input[type=text],
.bellissa-warranty-form input[type=password],
.bellissa-warranty-form input[type=email],
.bellissa-warranty-form input[type=tel],
.bellissa-warranty-form input[type=file],
.bellissa-warranty-form textarea,
.bellissa-warranty-form select {
  width: 100%;
  min-width: 250px;
  border-radius: 0.5rem;
  transition: all 0.3s ease;
  margin-bottom: 0;
  border: 1.6px solid #e4e4e4;
}
.bellissa-warranty-form input[type=date] {
  border: 1.6px solid #e4e4e4;
  border-radius: 0.5rem;
  transition: all 0.3s ease;
  padding: 6px 12px;
  margin-bottom: 0;
}
.bellissa-warranty-form .form-input {
  margin-bottom: 1.4rem;
}
.bellissa-warranty-form input[type=file]::file-selector-button {
  color: #fff;
  font-size: 15px;
  font-weight: normal;
  border-width: 1px;
  border-color: #c5213a;
  border-radius: 200px;
  background-color: #c5213a;
}
.bellissa-warranty-form input[type=file]::file-selector-button:hover {
  background-color: #b11e34;
}
.bellissa-warranty-form .field-description {
  font-style: italic;
}
.bellissa-warranty-form .field-activity-name {
  font-weight: bold;
}
.accordion-header {
  cursor: pointer;
  transition: 0.4s;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  padding: 1em 0.5em;
}
.accordion-header::after {
  font-family: "Font Awesome 5 Pro";
  content: "\F067";
  margin-left: auto;
  font-weight: lighter;
}
.accordion-header.active::after {
  content: "\F068";
}
.accordion-collapse {
  display: none;
  overflow: hidden;
}
.overlay_animation {
  width: auto;
  position: absolute;
  left: 40px;
  bottom: 12px;
  height: 100%;
  z-index: 1;
  box-sizing: border-box;
}
.overlay_animation.top {
  left: 0;
  top: 0;
  bottom: 0;
}
.overlay_animation.bee-animation {
  left: -40px;
}
.banner-section {
  z-index: 10;
}
@media (max-width: 992px) {
  .overlay_animation {
    bottom: 8px;
    height: initial;
  }
  .content-row__item__body {
    hyphens: auto;
  }
  .content-row__item__body h2 {
    padding-bottom: 2rem;
  }
  .content-row__item .u-margin-bottom--lg {
    margin-bottom: 0 !important;
  }
  .content-row__item p {
    margin-bottom: 0 !important;
  }
  .content-row__item .padding-size-sm,
  .content-row__item .padding-size-lg {
    padding: 2em !important;
  }
  .content-row__item .content-row__item__body ul {
    margin-bottom: 0 !important;
  }
  .content-row__item__body.padding-size-sm .content-row__item__body.padding-size-sm {
    padding: 0 !important;
  }
  .content-row__item__body.padding-size-md.padding-position-tb.margin-md.margin-position-around {
    margin: 0;
    padding: 3rem;
  }
  .content-row {
    margin: 0 !important;
  }
}
@media (max-width: 768px) {
  .formtable .columnCell {
    display: flex;
  }
  .formtable .columnCell .firstColumn,
  .formtable .columnCell .secondColumn {
    margin-bottom: 0;
  }
  .formtable .columnCell .fieldRow,
  .formtable .columnCell .descriptionRow {
    display: flex;
    flex-direction: column;
  }
  .corner-position-bl .padding-size-md,
  .corner-position-br .padding-size-md {
    padding: 6em;
  }
  .content-row__item .u-align-left {
    display: unset;
  }
  .content-row.content-row--3col,
  .content-row.content-row--4col:not(.content-row--column-gap-none),
  .content-row.content-row--1-2split {
    display: flex;
    flex-wrap: wrap;
  }
  .content-row.content-row--3col .content-row__item,
  .content-row.content-row--4col:not(.content-row--column-gap-none) .content-row__item,
  .content-row.content-row--1-2split .content-row__item {
    width: 100%;
    margin-bottom: 1em;
  }
  .buttons-collection {
    padding-top: 10px;
  }
  .content-container h4 {
    padding-top: 10px;
  }
}
ol:not([class]) {
  padding-left: 3rem;
}
/* Media Queries
–––––––––––––––––––––––––––––––––––––––––––––––––– */
/*
Note: The best way to structure the use of media queries is to create the queries
near the relevant code. For example, if you wanted to change the styles for buttons
on small devices, paste the mobile query code up in the buttons section and style it
there.
*/
